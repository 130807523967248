import AppSlider from 'components/AppSlider'
import React from 'react'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <>
      <AppSlider />
      <Helmet>
        <meta name="description" content='' />
        <title>ACTET</title>
        <link rel="canonical" href="https://actet2023.skit.ac.in/" />
      </Helmet>
      <div className='about'>
        <h2>CONFERENCE INFORMATION</h2>
        <div>
          <p>
            The landscape of computing has undergone a remarkable transformation with the rise of high-performance computing environments, virtualization, distributed and parallel computing, and the continuous enhancement of memory, storage, and computational power. This evolution has made the processing of highly complex scientific applications and vast volumes of data more accessible than ever before. Leveraging the latest advancements in computing software, hardware, and distributed platforms, the effective utilization of advanced computing techniques has become increasingly achievable.
          </p>
          <p>
            In light of these advancements, the International Conference on Advanced Computing Techniques in Engineering & Technology (ACTET-2025) endeavors to serve as a pivotal gathering point for both academia and industry practitioners. The primary objective is to delve into the foundational aspects of advanced scientific computing while exploring specific mechanisms and algorithms tailored to various domains within engineering and technology.
            <br />
          </p>
          <p>
            ACTET-2025 aims to facilitate the exchange of innovative ideas, knowledge, expertise, and experiences among researchers and practitioners. Through this collaborative platform, participants will have the opportunity to present recent research findings, identify emerging research challenges, and chart new directions in the realm of advanced computing techniques.<br />
          </p>
          <p>
            The Conference will provide a multifaceted forum, featuring diverse formats such as plenary talks, tutorials, project exhibitions, industry sessions, and paper presentations. These avenues will enable scholars to showcase novel research contributions spanning a spectrum of topics, including new scientific methods for computing, hybrid computing optimization techniques, advanced algorithms, and computational procedures.<br />
          </p>
          <p>
            The Conference particular focus will be the exploration of software and hardware solutions tailored to specific domains of science, such as Electrical and Electronics Engineering. By highlighting advancements in these areas, ACTET-2025 aims to foster interdisciplinary collaboration and innovation across various engineering and technology disciplines.<br />
          </p>
          <p>
            Building upon the foundation laid by its predecessors, including the International Conference on Advanced Computing Techniques in Engineering & Technology (ACTET-2023), this conference seeks to unite scholars and industry professionals in a shared pursuit of advancing the core principles of advanced scientific computing. Embracing the central theme of "<b>Empowering Smart Systems with Big Data, Machine Learning, and Optimization Strategies,</b>" ACTET-2025 aspires to catalyze transformative advancements in the field, propelling the development of intelligent systems equipped to tackle complex challenges in the digital age.
          </p>
          <p>
            <b>
              Publication: "<i>All accepted and registered papers will be published in  Springer CCIS book series</i>".
            </b>
          </p>
          <div style={{ display: "flex" }}>
            <div>
              <a href='/assets/accepted-paper-list.pdf' download >
                <button className="btn"><i className="fa fa-download"></i> Accepted Paper List
                </button>
              </a>
            </div>

            {/* <div style={{paddingLeft:"1rem"}}>
            <a href='/assets/ACTET-2023_Flyer.pdf' download >
              <button className="btn"><i className="fa fa-download"></i> FLYER
              </button>
            </a>
          </div> */}
          </div>
          {/* <a href="/assets/ACTETspecial session.pdf" className="url" download> <br/>DOWNLOAD: <i>Call for Special Session</i></a> */}
          {/* <div>
            <a href="/assets/List of Accepted Papers for publication.pdf" className="url" download> <br />CLICK HERE TO: <i>Accepted papers for CCIS Conference Proceedings</i></a>
            <p>
            •	The registration form of all accepted papers for Springer CCIS conference Proceedings has been sent to author mail Id.<br />
            </p>
            <p>
            •	The conference will be held in online mode only and the link for the same will be sent to all registered authors (Springer CCIS conference Proceedings) before commencement of conference.<br />
            </p>
            <p>
            •	The program schedule will be sent to all registered authors on their mail id.
            </p>
          </div> */}
        </div>
      </div >
      <div className='about' style={{ paddingTop: "0px" }}>
        <h2>IMPORTANT DATES</h2>
        <p>
          ● Paper Submission Date : <b>1-9-2024</b><br />
          ● Last Date of Paper Submission : <b>5-12-2024</b><br />
          ● Review Notification : <b><s>5-1-2025</s></b><b>&nbsp;<s>15-1-2025</s></b>&nbsp;<b>26-1-2025</b><br />
          {/* ● Revised Manuscript Submission : <b>10-01-2025</b><br /> */}
          ● Camera Ready Submission : <b><s>15-1-2025</s></b><b>&nbsp;10-2-2025</b>
        </p>
        {/* <ReviewCard /> */}

      </div>
    </>

  )
}

export default Home;
